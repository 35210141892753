import React from 'react'
import Layout from '../components/layout'
import { Container } from 'reactstrap'

const NotFoundPage = () => (
  <Layout>

    <Container
      style={{
        padding: '2rem 2rem 1rem',
        minHeight: '75vh'
      }}
    >
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Container>
  </Layout>
)

export default NotFoundPage
